<template>

  <main style="background-color: #F0F9EB;">
    <article v-loading="isSubmitting">
      <section>
        <div class="enquiry_a1">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <h1>Create your own dream with Access Uganda Tours</h1>
                <p class="fsp">
                  <span>We would love to hear from you!</span><br />
                  <span>
                    In order to provide you with the best suitable itinerary,
                    please fill in this form.
                  </span>
                </p>
              </div>
            </div>
            <div>
              <p class="fs5">Travel Information</p>

              <el-form
                :model="enquiryFormData"
                :rules="rules"
                label-position="top"
                ref="enquiryFormData"
                label-width="120px"
                class="demo-enquiryFormData"
              >
                <div class="row">
                  <div class="col-md-4">
                    <el-form-item
                      label="Full name"
                      prop="name"
                    >
                      <el-input v-model="enquiryFormData.name"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-4">
                    <el-form-item
                      label="Nationality"
                      prop="nationality"
                    >
                      <el-input v-model="enquiryFormData.nationality"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-4">
                    <el-form-item
                      label="Email"
                      prop="email"
                    >
                      <el-input v-model="enquiryFormData.email"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <el-form-item
                      label="Destinations (a combination of destinations is possible)"
                      prop="country"
                    >
                      <el-select
                        v-model="enquiryFormData.country"
                        placeholder="Choose Countries you would want to visit"
                        class="w-100"
                        :multiple="true"
                      >
                        <el-option
                          v-for="(country, index) in countries_list"
                          :key="index"
                          :label="country"
                          :value="country"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="col-md-4">
                    <el-form-item
                      label="Travel Period"
                      prop="travel_period"
                    >
                      <el-date-picker
                        v-model="enquiryFormData.travel_period"
                        type="month"
                        placeholder="Pick a month"
                        class="w-100"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </div>
                  <div class="col-md-4">
                    <el-form-item
                      label="Number of Days"
                      prop="number_of_days"
                    >
                      <el-input v-model.number="enquiryFormData.number_of_days"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <el-form-item
                      label="Number of Persons"
                      prop="number_of_persons"
                    >
                      <el-input v-model.number="enquiryFormData.number_of_persons"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-4">
                    <el-form-item
                      label="Accommodation Type"
                      prop="accommodation"
                    >
                      <el-select
                        v-model="enquiryFormData.accommodation"
                        placeholder="Choose Accommodation Type"
                        class="w-100"
                      >
                        <el-option
                          v-for="(item, index) in accommodation_types"
                          :key="index"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="col-md-4">
                    <el-form-item
                      label="Interests"
                      prop="interests"
                    >
                      <el-select
                        v-model="enquiryFormData.interests"
                        placeholder="Choose your interest"
                        class="w-100"
                        :multiple="true"
                      >
                        <el-option
                          v-for="(item, index) in interests"
                          :key="index"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item
                      label="Comments"
                      prop="comments"
                    >
                      <el-input
                        type="textarea"
                        v-model="enquiryFormData.comments"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <el-form-item>
                  <el-button
                    style="background-color: #D96B12; color: white;"
                    @click="submitForm('enquiryFormData')"
                  >Create Custom Tour <i class="el-icon-d-arrow-right"></i></el-button>
                </el-form-item>
              </el-form>

            </div>
          </div>
        </div>
      </section>
    </article>
  </main>
</template>

<script>
export default {
  data() {
    return {
      isSubmitting: false,
      countries_list: ["Uganda", "Kenya", "Tanzania", "Rwanda"],
      accommodation_types: ["Budget", "Middle Class", "Luxury"],
      budgets: [
        { name: "$0.00 - $1000", value: "$0.00 - $1000" },
        { name: "$1000 - $2000", value: "$1000 - $2000" },
        { name: "$2000 - $3000", value: "$2000 - $3000" },
        { name: "$3000 - $4000", value: "$3000 - $4000" },
        { name: "Not Important", value: "not_important" },
      ],
      interests: [
        "Birding",
        "Wildlife",
        "Primates(Gorillas & Chimpanzees)",
        "Mountaineering",
        "Culture",
      ],
      enquiryFormData: {
        name: "",
        nationality: "",
        email: "",
        country: [],
        travel_period: "",
        number_of_days: "",
        number_of_persons: "",
        accommodation: "",
        budget_indication: "",
        interests: [],
        comments: "",
      },

      rules: {
        name: [
          {
            required: true,
            message: "Full Name is required",
            trigger: "blur",
          },
        ],
        nationality: [
          {
            required: true,
            message: "Nationality is required",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Email is required",
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: "Country of destination is required",
            trigger: "change",
          },
        ],
        travel_period: [
          {
            required: true,
            message: "Travel Period is required",
            trigger: "change",
          },
        ],
        number_of_days: [
          {
            required: true,
            message: "Number of days are required",
            trigger: "blur",
          },
        ],
        number_of_persons: [
          {
            required: true,
            message: "Number of Persons are required",
            trigger: "blur",
          },
        ],
        accommodation: [
          {
            required: true,
            message: "accommodation is required",
            trigger: "blur",
          },
        ],
        budget_indication: [
          {
            required: true,
            message: "budget_indication is required",
            trigger: "change",
          },
        ],
        interests: [
          {
            required: true,
            message: "interests are required",
            trigger: "change",
          },
        ],
        comments: [
          {
            required: true,
            message: "Comment is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.isSubmitting = true;
            let request = await this.$http.post(
              `api/enquiry/add`,
              this.enquiryFormData
            );
            if (
              request.data.success &&
              request.data.message == "ENQUIRY_ADDED_SUCCESSFULLY"
            ) {
              this.$notify({
                title: "Success",
                message: "Tailor Made Safari Submitted Successfully",
                type: "success",
              });
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isSubmitting = false;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred please try again",
                type: "warning",
              });
            }
            this.isSubmitting = false;
            this.$notify({
              title: "Submission Failed",
              message: "Unable to Submit Tailor Made Safari, Please try again",
              type: "error",
            });
          } finally {
            this.isSubmitting = false;
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.enquiry_a1 {
  padding: 60px 0px 100px 0px;
}
label {
  margin-top: 20px;
  color: rgb(83, 82, 82);
}

.enquiry_a1 h1 {
  font-size: 1.6em;
  font-weight: 600;
}

.enquiry_a1 p {
  font-size: 1em;
  font-weight: 300;
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .enquiry_a1 h1 {
    font-size: 1.2em;
    font-weight: 600;
  }

  .enquiry_a1 p {
    font-size: 1em;
    font-weight: 300;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .enquiry_a1 h1 {
    font-size: 1.2em;
    font-weight: 600;
  }

  .enquiry_a1 p {
    font-size: 1em;
    font-weight: 300;
  }
}
</style>